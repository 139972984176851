import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/appSidebar",
      props: true,
      component: () => import("./components/Common/appSidebar"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "/DashboardPage",
          name: "DashboardPage",
          meta: {
            requireAuth: true, roles: ['admin'] 
          },
          component: () => import("./components/admin/DashboardPage.vue"),
        },
        {
          path: "/Dashboard",
          name: "NewDashboard",
          meta: {
            requireAuth: true, roles: ['admin'] 
          },
          component: () => import("./components/admin/NewDashboard.vue"),
        },
        {
          path: "/Report",
          name: "Report",
          meta: {
            requireAuth: true, roles: ['admin'] 
          },
          component: () => import("./components/admin/Report.vue"),
        },
        {
          path: "/IB",
          name: "IB",
          meta: {
            requireAuth: true, roles: ['user'] 
          },
          component: () => import("./components/user/IbPage.vue"),
        },

        {
          path: "/UserMybookings",
          name: "UserMybookings",
          meta: {
            requireAuth: true, roles: ['user'] 
          },
          component: () => import("./components/user/UserMybookings.vue"),
        },
        // {
        //   path: "/mybooking",
        //   name: "mybooking",
        //   meta: {
        //     requireAuth: true,
        //   },
        //   component: () => import("./components/user/MyBooking.vue"),
        // },


        {
          path: "/cancelled",
          name: "cancelled",
          meta: {
            requireAuth: true, roles: ['admin'] 
          },
          component: () => import("./components/admin/CanclledBooking.vue"),
        },
        {
          path: "/tomorrow",
          name: "tomorrow",
          meta: {
            requireAuth: true, roles: ['admin'] 
          },
          component: () => import("./components/admin/BookinsTomorrow.vue"),
        },


        {
          path: "/booking",
          name: "booking",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/admin/BookingPage.vue"),
        },
        {
          path: "/view",
          name: "view",
          meta: {
            requireAuth: true, roles: ['admin'] 
          },
          component: () => import("./components/admin/BookingDetailedview.vue"),
        },
        {
          path: "/divisionib",
          name: "divisionib",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/IbPage.vue"),
        },

        {
          path: "/bookib",
          name: "bookib",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/IbBook.vue"),
        },
        {
          path: "/IbBookUser",
          name: "IbBookUser",
          meta: {
            requireAuth: true, roles: ['user'] 
          },
          component: () => import("./components/user/IbBookUser.vue"),
        },
        {
          path: "/mybookings",
          name: "mybookings",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/MyBooking.vue"),
        },

        {
          path: "/manager",
          name: "AddManager",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/AddManager.vue"),
        },

        {
          path: "/report",
          name: "report",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/Report.vue"),
        },

        {
          path: "/divisiondashboard",
          name: "divisiondashboard",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/Dashboard.vue"),
        },
        {
          path: "/SuccessPage",
          name: "SuccessPage",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/payment/SuccessPage.vue"),
        },
        {
          path: "/FailurePage",
          name: "FailurePage",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/payment/FailurePage.vue"),
        },
        {
          path: "/paywaitscreen",
          name: "paywaitscreen",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/payment/PayWaitScreen.vue"),
        },

        {
          path: "/CancelledBookings",
          name: "CancelledBookings",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/CancelledBookings.vue"),
        },
        {
          path: "/BookingTomorrow",
          name: "BookingTomorrow",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/BookingTomorrow.vue"),
        },

        {
          path: "/BlockDates",
          name: "BlockDates",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/BlockDates.vue"),
        },
        

        {
          path: "/viewReservations",
          name: "ViewReservations",
          meta: {
            requireAuth: true, roles: ['division'] 
          },
          component: () => import("./components/division/ViewReservations.vue"),
        },

        {
          path: "/blockedates",
          name: "blockedates",
          meta: {
            requireAuth: true, roles: ['user'] 
          },
          component: () => import("./components/user/BlockedDates.vue"),
        },

        {
          path: "/managerdashboard",
          name: "managerdashboard",
          meta: {
            requireAuth: true, roles: ['manager'] 
          },
          component: () => import("./components/Manager/ManagerDashboard.vue"),
        },
        {
          path: "/manage",
          name: "manage",
          meta: {
            requireAuth: true, roles: ['manager'] 
          },
          component: () => import("./components/Manager/ManagePage.vue"),
        },

        {
          path: "/manageedit",
          name: "manageedit",
          meta: {
            requireAuth: true, roles: ['manager'] 
          },
          component: () => import("./components/Manager/EditPage.vue"),
        },

        {
          path: "/adminib",
          name: "adminib",
          meta: {
            requireAuth: true, roles: ['admin','division'] 
          },
          component: () => import("./components/admin/IbPage.vue"),
        },

        

        {
          path: "/addib",
          name: "addib",
          meta: {
            requireAuth: true, roles: ['admin']
          },
          component: () => import("./components/admin/AddIb.vue"),
        },

        {
          path: "/editib",
          name: "editib",
          meta: {
            requireAuth: true, roles: ['admin','division']
          },
          component: () => import("./components/admin/EditIb.vue"),
        },
        {
          path: "/page1",


          component: () => import("./components/TestPage"),
        },
        {
          path: "/pending",
          name: "PendingApplication",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/admin/PendingApplication.vue"),
        },
        {
          path: "/prepending",
          name: "PrePendingApplications",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/admin/PrePendingApplications.vue"),
        },
      ],
    },
    {
      path: "/",
      name: "LoginPage",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/LoginPage"),
    },
    {
      path: "/ForgotPassword",
      name: "ForgotPassword",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/ForgotPassword"),
    },
    {
      path: "/register",
      name: "register",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/RegisterPage"),
    },
    {
      path: '/OtpPage',
      name: 'OtpPage',
      meta: {
        requireAuth: false,
      },
      component: function () {
        return import(/* webpackChunkName: "about" */ './components/Login/OtpPage.vue')
      }
    },
    // {
    //     path: '/',
    //     props: true,
    //     name: 'AdminDashboard',
    //     component: () =>
    //         import ('./components/Common/appDashboard'),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      meta: {
        requiresAuth: false,
      },
      component: () => import("./components/Common/404"),
    },

  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requireAuth == true) && !store.state.isLoggedIn) {
    next({ name: "LoginPage", params: { lastPage: from } });
    return;
  }

  if (store.state.isLoggedIn) {
    axios({
      method: "GET",
      url: "/token/authenticate",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.data.status) {
          store.commit("sessionOut", true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  if (to.name === 'LoginPage' && store.state.isLoggedIn) {
    redirectToDashboard();
    return;
  }

  if (to.matched.some(route => route.meta.requireAuth)) {
    const userType = getUserType();
    const isAuthorized = to.matched.every(route => {
      return route.meta.roles ? route.meta.roles.includes(userType) : true;
    });

    if (!isAuthorized) {
      next({ name: '404PageNotFound' });
      return;
    }
  }

  next();
});

//   if (to.name == "LoginPage" && store.state.isLoggedIn) {
//     const userType = getUserType();
//     console.log("User Type:", userType);

//     if (userType == "admin") {
//       console.log("admin");
//       next({ name: "DashboardPage" });
//     } else if (userType == "user") {
//       console.log("user");
//       next({ name: "IB" });
//     } else {
//       next({ name: "divisiondashboard" });
//       console.log("division");
//     }
//     return;
//   }

//   next();
// });

// if (to.name === 'LoginPage' && store.state.isLoggedIn) {
//   redirectToDashboard();
//   return;
// }
// if (to.matched.some(route => route.meta.requireAuth)) {
//   const userType = getUserType();
//   const isAuthorized = to.matched.every(route => {
//     return route.meta.roles ? route.meta.roles.includes(userType) : true;
//   });

//   if (!isAuthorized) {
//     next({ name: '404PageNotFound' });
//     return;
//   }
// }

// next();
// });

function getUserType() {
  const utype = localStorage.getItem("utype");
  console.log("utype:", utype);   
  if (utype === "777") {
    return "admin";
  } else if (utype === "772") {
    return "user";
  } else if (utype === "7741") {
    return "manager";
  } else {
    return "division";
  }
}

function redirectToDashboard() {
  const userType = getUserType();
  console.log('User Type:', userType);

  if (userType === 'admin') {
    console.log('admin');
    router.push({ name: 'DashboardPage' });
  } else if (userType === 'user') {
    console.log('user');
    router.push({ name: 'IB' });
  } else if (userType === 'manager') {
    console.log('manager');
    router.push({ name: 'managerdashboard' });
  } else {
    console.log('division');
    router.push({ name: 'divisiondashboard' });
  }
}

export default router;